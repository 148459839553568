export const userCoachesData = [
    {
        id: "#CO14",
        img: "assets/img/featured/featured-01.jpg",
        coachName: "Kevin Anderson ",
        bookedOn: "Booked on : 25 May 2023",
        invoice: "Onetime",
        dateAndTime: "Mon, Jul 11",
        time: "04:00 PM - 06:00 PM",
        payment: "$150",
        paidon: "Mon, Jul 12",
        status: "Paid",
        download: "Download",
    },
    {
        id: "#CO15",
        img: "assets/img/featured/featured-02.jpg",
        coachName: "Angela Roudrigez",
        bookedOn: "Booked on : 26 May 2023",
        invoice: "Single Lesson",
        dateAndTime: "Mon, Jul 1101:00 PM - 04:00 PM",
        payment: "$200",
        paidon: "Mon, Jul 12",
        status: "Pending",
        download: "Download",
    },
    {
        id: "#CO16",
        img: "assets/img/featured/featured-03.jpg",
        coachName: "Evon Raddick",
        bookedOn: "Booked on : 27 May 2023",
        invoice: "Onetime",
        dateAndTime: "Mon, Jul 1105:00 PM - 08:00 PM",
        payment: "$150",
        paidon: "Mon, Jul 12",
        status: "Failed",
        download: "Download",
    },
    {
        id: "#CO17",
        img: "assets/img/featured/featured-04.jpg",
        coachName: "Harry Richardson",
        bookedOn: "Booked on : 28 May 2023",
        invoice: "Onetime",
        dateAndTime: "Mon, Jul 1101:00 PM - 04:00 PM",
        payment: "$90",
        paidon: "Mon, Jul 20",
        status: "Paid",
        download: "Download",
    },
    {
        id: "#CO18",
        img: "assets/img/featured/featured-05.jpg",
        coachName: "Pete Hill",
        bookedOn: "Booked on : 29 May 2023",
        invoice: "Onetime",
        dateAndTime: "Mon, Jul 1103:00 PM - 08:00 PM",
        payment: "$180",
        paidon: "Mon, Jul 12",
        status: "Paid",
        download: "Download",
    },
];
