export const coachRequest = [
    {
        courtName: "BWing Sports Academy",
        img1: "assets/img/booking/booking-03.jpg",
        img2: "assets/img/profiles/avatar-06.jpg",
        courtNumber: "Court 1 ",
        bookingTime: "Booked on : 26 May 2023",
        playerName: "Johnson",
        date: "Mon, Jul 11",
        time: "06:00 PM - 08:00 PM",
        additionalGuests: "2",
        payment: "$130",
        details: "View Details",
        chat: "Chat",
    },
    {
        courtName: "Feather Badminton",
        img1: "assets/img/booking/booking-02.jpg",
        img2: "assets/img/profiles/avatar-02.jpg",
        courtNumber: "Court 1 ",
        bookingTime: "Booked on : 26 May 2023",
        playerName: "Andy",
        date: "Mon, Jul 11",
        time: "06:00 PM - 08:00 PM",
        additionalGuests: "2",
        payment: "$150",
        details: "View Details",
        chat: "Chat",
    },
    {
        courtName: "Marsh Academy",
        img1: "assets/img/booking/booking-01.jpg",
        img2: "assets/img/profiles/avatar-01.jpg",
        courtNumber: "Court 1 ",
        bookingTime: "Booked on : 26 May 2023",
        playerName: "Johnson",
        date: "Mon, Jul 11",
        time: "06:00 PM - 08:00 PM",
        additionalGuests: "2",
        payment: "$120",
        details: "View Details",
        chat: "Chat",
    },
    {
        courtName: "Wing Sports Academy",
        img1: "assets/img/booking/booking-04.jpg",
        img2: "assets/img/profiles/avatar-03.jpg",
        courtNumber: "Court 1 ",
        bookingTime: "Booked on : 26 May 2023",
        playerName: "Ariyan",
        date: "Mon, Jul 11",
        time: "06:00 PM - 08:00 PM",
        additionalGuests: "2",
        payment: "$250",
        details: "View Details",
        chat: "Chat",
    },
];
