import React from "react";
import {Link, useLocation} from "react-router-dom";
import {all_routes} from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import {localStorageFunctions} from "../../helpers/localStorage.helper";
import {toast} from "react-toastify";
import { brainCiUserInterface } from "../../core/data/interface/model";

const Header = () => {
    const routes = all_routes;
    const location = useLocation();
    const isUserConnected : brainCiUserInterface = localStorageFunctions.getUser();
    console.log(location, "location");

    const header = [
        {
            tittle: "Accueil",
            showAsTab: false,
            separateRoute: true,
            routes: routes.home,
            hasSubRoute: false,
            showSubRoute: false,
        },
        {
            tittle: "À propos de nous",
            showAsTab: false,
            separateRoute: true,
            routes: routes.aboutUs,
            hasSubRoute: false,
            showSubRoute: false,
        },
        {
            tittle: "Packs",
            showAsTab: false,
            separateRoute: true,
            routes: routes.pricing,
            hasSubRoute: false,
            showSubRoute: false,
        },
        {
            tittle: "FAQ",
            showAsTab: false,
            separateRoute: true,
            routes: routes.faq,
            hasSubRoute: false,
            showSubRoute: false,
        },
        {
            tittle: "Nous contacter",
            showAsTab: false,
            separateRoute: true,
            routes: routes.contactUs,
            hasSubRoute: false,
            showSubRoute: false,
        },

        // {
        //     tittle: "Coaches",
        //     showAsTab: false,
        //     separateRoute: false,
        //     menu: [
        //         {
        //             menuValue: "Coaches Map",
        //             hasSubRoute: true,
        //             showSubRoute: true,
        //             subMenus: [
        //                 {
        //                     menuValue: "Coaches Map",
        //                     routes: routes.coachesMap,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Coaches Map Sidebar",
        //                     routes: routes.coachesMapSidebar,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Coaches Grid",
        //             routes: routes.coachesGrid,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Coaches List",
        //             routes: routes.coachesList,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Coaches Grid Sidebar",
        //             routes: routes.coachesGridSidebar,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Coaches List Sidebar",
        //             routes: routes.coachesListSidebar,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Booking",
        //             hasSubRoute: true,
        //             showSubRoute: true,
        //             subMenus: [
        //                 {
        //                     menuValue: "Book a Court",
        //                     routes: routes.cagedetails,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Book a Coach",
        //                     routes: routes.coachDetails,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Coaches Détails",
        //             routes: routes.coachDetails,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Venue",
        //             hasSubRoute: true,
        //             showSubRoute: true,
        //             subMenus: [
        //                 {
        //                     menuValue: "Venue List",
        //                     routes: routes.listingList,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Venue Détails",
        //                     routes: routes.venueDetails,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Coaches Dashboard",
        //             routes: routes.coachDashboard,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Coach Courts",
        //             routes: routes.allCourt,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "List Your Cart",
        //             routes: routes.addCourt,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Chat",
        //             routes: routes.coachChat,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //     ],
        // },
        // {
        //     tittle: "User",
        //     showAsTab: false,
        //     separateRoute: false,
        //     menu: [
        //         {
        //             menuValue: "User Dashboard",
        //             routes: routes.userDashboard,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Bookings",
        //             routes: routes.userBookings,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Chat",
        //             routes: routes.userChat,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Invoice",
        //             routes: routes.userInvoice,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Wallet",
        //             routes: routes.userWallet,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Profile Edit",
        //             routes: routes.userProfileOtherSetting,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Change Password",
        //             routes: routes.userSettingPassword,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Other Settings",
        //             routes: routes.profileOtherSetting,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //     ],
        // },
        // {
        //     tittle: "Pages",
        //     showAsTab: false,
        //     separateRoute: false,
        //     menu: [
        //         {
        //             menuValue: "À propos de nous",
        //             routes: routes.aboutUs,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Our Team",
        //             routes: routes.ourTeams,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "services",
        //             routes: routes.services,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Events",
        //             routes: routes.events,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Authentication",
        //             hasSubRoute: true,
        //             showSubRoute: true,
        //             subMenus: [
        //                 {
        //                     menuValue: "Signup",
        //                     routes: routes.register,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Signin",
        //                     routes: routes.login,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Forgot Password",
        //                     routes: routes.forgotPasssword,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Reset Password",
        //                     routes: routes.changePassword,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Error Page",
        //             hasSubRoute: true,
        //             showSubRoute: false,
        //             subMenus: [
        //                 {
        //                     menuValue: "404 Error",
        //                     routes: routes.error404,
        //                     hasSubRoute: false,
        //                     showSubRoute: false,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Pricing",
        //             routes: routes.pricing,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "FAQ",
        //             routes: routes.faq,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Gallery",
        //             routes: routes.gallery,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Testimonials",
        //             routes: routes.testimonials,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Terms & Conditions",
        //             routes: routes.termsCondition,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Privacy Policy",
        //             routes: routes.privacyPolicy,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Maintenance",
        //             routes: routes.maintenance,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //         {
        //             menuValue: "Coming Soon",
        //             routes: routes.comingSoon,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //         },
        //     ],
        // },
        // {
        //     tittle: "Blog",
        //     showAsTab: false,
        //     separateRoute: false,
        //     menu: [
        //         {
        //             menuValue: "Blog List",
        //             routes: routes.blogList,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },

        //         {
        //             menuValue: "Blog List Sidebar",
        //             hasSubRoute: true,
        //             showSubRoute: true,
        //             subMenus: [
        //                 {
        //                     menuValue: "Blog List Sidebar Left",
        //                     routes: routes.blogListSidebarLeft,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Blog List Sidebar Right",
        //                     routes: routes.blogListSidebarRight,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Blog Grid",
        //             routes: routes.blogGrid,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Blog Grid Sidebar",
        //             hasSubRoute: true,
        //             showSubRoute: true,
        //             subMenus: [
        //                 {
        //                     menuValue: "Blog Grid Sidebar Left",
        //                     routes: routes.blogGridSidebarLeft,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Blog Grid Sidebar Right",
        //                     routes: routes.blogGridSidebarRight,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Blog Detail",
        //             routes: routes.blogDetails,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //         {
        //             menuValue: "Blog Detail Sidebar",
        //             hasSubRoute: true,
        //             showSubRoute: true,
        //             subMenus: [
        //                 {
        //                     menuValue: "Blog Detail Sidebar Left",
        //                     routes: routes.blogDetailsSidebarLeft,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //                 {
        //                     menuValue: "Blog Detail Sidebar Right",
        //                     routes: routes.blogDetailsSidebarRight,
        //                     hasSubRoute: true,
        //                     showSubRoute: true,
        //                     subMenus: [],
        //                 },
        //             ],
        //         },
        //         {
        //             menuValue: "Blog Carousel",
        //             routes: routes.blogCarousel,
        //             hasSubRoute: false,
        //             showSubRoute: false,
        //             subMenus: [],
        //         },
        //     ],
        // },
    ];

    const customStyle = {
        background: location.pathname.includes(routes.home) ? "#61bfce" : "#74aef5",
    };

    function handleLogout(event: React.MouseEvent<HTMLElement>): void {
        if (isUserConnected?.firstName) {
            localStorageFunctions.removeToken();
            localStorageFunctions.removeUser();
            window.location.href = routes.login;
        } else {
            toast.info("Veuillez vous connecter.", {
                toastId: "defaultToast",
                theme: "colored",
            });
        }
    }

    return (
        <header
            className={
                location.pathname.includes(routes.home)
                    ? "header header-trans"
                    : "header header-sticky"
            }
            style={customStyle}
        >
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg header-nav">
                    <div className="navbar-header">
                        <Link id="mobile_btn" to="#">
                            <span className="bar-icon">
                                <span />
                                <span />
                                <span />
                            </span>
                        </Link>
                        <Link to={routes.home} className="navbar-brand logo">
                            {/* <ImageWithBasePath src="assets/img/logos/white-blue-ida-logo.png" className="img-fluid" alt="Logo" /> */}

                            {location.pathname.includes(routes.home) ? (
                                <ImageWithBasePath
                                    src="assets/img/logos/white-blue-ida-logo.png"
                                    className="img-fluid"
                                    alt="Logo blue"
                                    height={"auto"}
                                    width={210}
                                />
                            ) : (
                                <ImageWithBasePath
                                    src="assets/img/logos/white-ida-logo.png"
                                    className="img-fluid"
                                    alt="Logo white"
                                    height={"auto"}
                                    width={210}
                                />
                            )}
                        </Link>
                    </div>
                    <div className="main-menu-wrapper">
                        <div className="menu-header">
                            <Link to={routes.home} className="menu-logo">
                                <ImageWithBasePath
                                    src="assets/img/logos/blue-logo.png"
                                    className="img-fluid"
                                    alt="Logo"
                                />
                            </Link>
                            <Link id="menu_close" className="menu-close" to="#">
                                {" "}
                                <i className="fas fa-times" />
                            </Link>
                        </div>
                        <ul className="main-nav">
                            {header.map((mainMenus, mainIndex) => (
                                <React.Fragment key={mainIndex}>
                                    {mainMenus.separateRoute ? (
                                        <li
                                            key={mainIndex}
                                            className={
                                                location.pathname.includes(mainMenus.routes)
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to={mainMenus.routes}>{mainMenus.tittle}</Link>
                                        </li>
                                    ) : (
                                        <li
                                            className={`has-submenu ${mainMenus?.menu?.map((item) => item?.routes).includes(location.pathname) ? "active" : ""}`}
                                        >
                                            <Link to="#">
                                                {mainMenus.tittle}{" "}
                                                <i className="fas fa-chevron-down"></i>
                                            </Link>
                                            <ul
                                                className={`submenu ${mainMenus.showAsTab ? "d-block" : ""}`}
                                            >
                                                {mainMenus?.menu?.map((menu, menuIndex) => (
                                                    <li
                                                        key={menuIndex}
                                                        className={`${menu.hasSubRoute ? "has-submenu" : ""} ${menu?.subMenus?.map((item) => item?.routes).includes(location.pathname) ? "active" : ""}`}
                                                    >
                                                        {menu.hasSubRoute ? (
                                                            <React.Fragment>
                                                                <Link to="#">{menu.menuValue}</Link>
                                                                <ul
                                                                    className={`submenu ${menu.showSubRoute ? "d-block" : ""}`}
                                                                >
                                                                    {menu.subMenus?.map(
                                                                        (subMenu, subMenuIndex) => (
                                                                            <li key={subMenuIndex}>
                                                                                <Link
                                                                                    to={
                                                                                        subMenu.routes
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        subMenu.menuValue
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </React.Fragment>
                                                        ) : (
                                                            <li
                                                                className={
                                                                    location.pathname.includes(
                                                                        menu.routes
                                                                    )
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                            >
                                                                <Link to={menu.routes}>
                                                                    {menu.menuValue}
                                                                </Link>
                                                            </li>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    )}
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                    <ul className="nav header-navbar-rht">
                        {isUserConnected && isUserConnected?.firstName && (
                            <>
                                
                                <li className="nav-item">
                                    <Link
                                        className="nav-link btn btn-secondary"
                                        to={routes.userBookings}
                                    >
                                        <span>
                                            <i className="feather-menu" />
                                        </span>
                                        Tableau de Bord
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-link btn btn-white log-register">
                                        <Link to="" onClick={handleLogout}>
                                            <span>
                                                <i className="feather-log-out" />
                                            </span>
                                            Se déconnecter
                                        </Link>{" "}
                                    </div>
                                </li>

                            </>
                        )}
                        {!isUserConnected?.firstName && (
                            <>
                            <li className="nav-item">
                                <Link to={routes.login}>
                                    <span>
                                        <i className="feather-users" />
                                    </span>
                                    Se connecter /
                                </Link>{" "}
                                <Link to={routes.login}>S&apos;inscrire</Link>
                                </li>
                            </>
                        )}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
