import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {all_routes} from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import {CreateClientInitialValues} from "../../core/data/json/create_client_init";
import {cleanData, EMAIL_REGEX} from "../../helpers/input.helper";
import {backendFunctions} from "../../helpers/backend.helper";
import {toast} from "react-toastify";

const Signin = () => {
    const route = all_routes;
    //Toggle Password
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState("");

    // State
    const [state, setState] = useState(CreateClientInitialValues);

    useEffect(() => {
        cleanData(state);
    }, [state]);

    // Terms conditions check state
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prev) => !prev);
    };

    //ToggleConfirmPassword
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible((prev) => !prev);
    };

    // TODO Faire l'intégration ici ( with comments if possible)
    const resetState = () => {
        setState(CreateClientInitialValues);
        setPassword("");
        setConfirmPassword("");
    };

    const handleChange = ({target: {name, value}}: any) =>
        setState((previousState: any) => ({
            ...previousState,
            [name]: name === "email" ? value?.trim?.() : value,
        }));

    const handleRegister = (e: any) => {
        console.log("🚀 ~ handleRegister ~ state:", state);

        e?.preventDefault?.();

        if (state.email.trim().length === 0 && state.pwd.trim().length === 0) {
            toast.warn("Tous les champs sont obligatoires.", {
                toastId: "defaultToast",
                theme: "colored",
            });
            return;
        }
        if (!EMAIL_REGEX.test(state.email)) {
            toast.warn("Veuillez entrer une adresse mail s'il vous plaît.", {
                toastId: "defaultToast",
                theme: "colored",
            });
            return;
        }
        if (password !== confirmPassword) {
            toast.warn("Veuillez faire correspondre les mots de passe s'il vous plaît.", {
                toastId: "defaultToast",
                theme: "colored",
            });
            return;
        }
        state.email = state.email.trim();
        console.log("🚀 ~ handleRegister ~ state.email:", state.email);
        state.externalAuthProvider = null;

        setLoading(true);
        backendFunctions.auth
            .register(state)
            .then((response: any) => {
                setLoading(false);

                toast.success(
                    "Inscription réussie. Vous serez redirigé(e) vers la page de connexion...",
                    {
                        toastId: "defaultToast",
                        theme: "colored",
                    }
                );
                resetState();
                setTimeout(() => {
                    window.location.href = route.login;
                }, 4000);
                console.log("🚀 ~ handleRegister ~ response:", response);
            })
            .catch((error) => {
                toast.error("une erreur est survenue", {
                    toastId: "defaultToast",
                    theme: "colored",
                });
                resetState();
                setLoading(false);
                console.log("🚀 ~ handleRegister ~ error:", error);
            });
    };

    return (
        <div>
            <>
                {/* Main Wrapper */}
                <div className="main-wrapper authendication-pages">
                    {/* Page Content */}
                    <div className="content">
                        <div className="container wrapper no-padding">
                            <div className="row no-margin vph-100">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
                                    <div className="banner-bg register">
                                        <div className="row no-margin h-100">
                                            <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                                                <div className="h-100 d-flex justify-content-center align-items-center">
                                                    <div className="text-bg register text-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-limegreen text-capitalize"
                                                        >
                                                            <i className="fa-solid fa-thumbs-up me-3" />
                                                            S&apos;inscrire
                                                        </button>
                                                        <p>
                                                            Ensemble, faisons de chaque match une
                                                            opportunité d&apos;innovation et de
                                                            progrès.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 no-padding">
                                    <div className="dull-pg">
                                        <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                                            <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                                                <header className="text-center">
                                                    <Link to={route.home}>
                                                        <ImageWithBasePath
                                                            src="assets/img/logos/blue-logo.png"
                                                            className="img-fluid"
                                                            alt="Logo"
                                                            height={"auto"}
                                                            width={225}
                                                        />
                                                    </Link>
                                                </header>
                                                <div className="shadow-card">
                                                    <h2>Créer un compte</h2>
                                                    <p>
                                                        Renseignez les champs du formulaire
                                                        ci-dessous
                                                    </p>

                                                    <div className="tab-content" id="myTabContent">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="user"
                                                            role="tabpanel"
                                                            aria-labelledby="user-tab"
                                                        >
                                                            {/* Register Form */}
                                                            {/* TODO : Integrer default register ✅ */}
                                                            <form>
                                                                <div className="form-group">
                                                                    <div className="group-img">
                                                                        <i className="feather-user" />
                                                                        <input
                                                                            name="firstName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Prénom"
                                                                            onChange={handleChange}
                                                                            value={state.firstName}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="group-img">
                                                                        <i className="feather-user" />
                                                                        <input
                                                                            name="lastName"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Nom"
                                                                            onChange={handleChange}
                                                                            value={state.lastName}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="group-img">
                                                                        <i className="feather-mail" />
                                                                        <input
                                                                            name="email"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Adresse e-mail"
                                                                            onChange={handleChange}
                                                                            value={state.email}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="pass-group group-img">
                                                                        <i
                                                                            className={`toggle-password ${passwordVisible ? "feather-eye" : "feather-eye-off"}`}
                                                                            onClick={
                                                                                togglePasswordVisibility
                                                                            }
                                                                        />
                                                                        <input
                                                                            name="pwd"
                                                                            type={
                                                                                passwordVisible
                                                                                    ? "text"
                                                                                    : "password"
                                                                            }
                                                                            className="form-control pass-input"
                                                                            placeholder="Mot de passe"
                                                                            value={password}
                                                                            onChange={(e) => {
                                                                                setPassword(
                                                                                    e.target.value
                                                                                );
                                                                                handleChange(e);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="pass-group group-img">
                                                                        <i
                                                                            className={`toggle-password ${confirmPasswordVisible ? "feather-eye" : "feather-eye-off"}`}
                                                                            onClick={
                                                                                toggleConfirmPasswordVisibility
                                                                            }
                                                                        />
                                                                        <input
                                                                            type={
                                                                                confirmPasswordVisible
                                                                                    ? "text"
                                                                                    : "password"
                                                                            }
                                                                            className="form-control pass-input"
                                                                            placeholder="Confirmer le mot de passe"
                                                                            value={confirmPassword}
                                                                            onChange={(e) =>
                                                                                setConfirmPassword(
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* ✅ TODO : Faire un toggle sur ce chek. checked ? submitButton.disabled = false : submitButton.disabled = true  */}
                                                                <div className="form-check d-flex justify-content-start align-items-center policy">
                                                                    <div className="d-inline-block">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            defaultValue={
                                                                                checked
                                                                                    ? "true"
                                                                                    : "false"
                                                                            }
                                                                            id="policy"
                                                                            onClick={() =>
                                                                                setChecked(
                                                                                    (prevState) =>
                                                                                        !prevState
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="policy"
                                                                    >
                                                                        En cochant cette case, je
                                                                        reconnais avoir pris
                                                                        connaissance des{" "}
                                                                        <Link
                                                                            to={
                                                                                route.termsCondition
                                                                            }
                                                                        >
                                                                            Conditions Générales
                                                                            d&apos;Utilisation
                                                                        </Link>{" "}
                                                                        du site ainsi que de la{" "}
                                                                        <Link
                                                                            to={route.privacyPolicy}
                                                                        >
                                                                            Politique de
                                                                            Confidentialité
                                                                        </Link>{" "}
                                                                        et je les accepte.
                                                                    </label>
                                                                </div>
                                                                <Link
                                                                    to={"#"}
                                                                    className={
                                                                        checked &&
                                                                        Object.keys(state)
                                                                            .length === 4 &&
                                                                        password === confirmPassword
                                                                            ? "btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                                                                            : "disabled btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                                                                    }
                                                                    type="submit"
                                                                    onClick={handleRegister}
                                                                >
                                                                    {loading
                                                                        ? "Inscription en cours..."
                                                                        : "S'inscrire"}
                                                                    <i className="feather-arrow-right-circle ms-2" />
                                                                </Link>
                                                                <div className="form-group">
                                                                    <div className="login-options text-center">
                                                                        <span className="text">
                                                                            Ou continuez avec
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {/* TODO : Google and Facebook Register button. Intégrer l'auth des deux */}
                                                                <div className="form-group mb-0">
                                                                    <ul className="social-login d-flex justify-content-center align-items-center">
                                                                        <li className="text-center">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-social d-flex align-items-center justify-content-center"
                                                                                onClick={() => handleExternalAuthLogin("GOOGLE")}
                                                                                >
                                                                                <ImageWithBasePath
                                                                                    src="assets/img/icons/google.svg"
                                                                                    className="img-fluid"
                                                                                    alt="Google"
                                                                                    />
                                                                                <span>Google</span>
                                                                            </button>
                                                                        </li>
                                                                        <li className="text-center">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-social d-flex align-items-center justify-content-center"
                                                                                onClick={() => handleExternalAuthLogin("FACEBOOK")}
                                                                            >
                                                                                <ImageWithBasePath
                                                                                    src="assets/img/icons/facebook.svg"
                                                                                    className="img-fluid"
                                                                                    alt="Facebook"
                                                                                />
                                                                                <span>
                                                                                    Facebook
                                                                                </span>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </form>
                                                            {/* /Register Form */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bottom-text text-center">
                                                    <p>
                                                        Vous avez déjà un compte ?{" "}
                                                        <Link to={route.login}>
                                                            Connectez-vous !
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Page Content */}
                </div>
                {/* /Main Wrapper */}
            </>
        </div>
    );
};

export default Signin;
