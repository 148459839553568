export const userbookingdata = [
    {
        "6": "Cancel Booking\n                                      \n                                      \n                                        \n                                        Edit\n                                      \n                                      \n                                        \n                                        Delete",
        courtName: "Leap Sports Academy",
        img: "assets/img/booking/booking-01.jpg",
        court: "Court 1",
        dateandTime: "Mon, Jul 1106:00 PM - 08:00 PM",
        payment: "$120",
        status: "Accepted",
        details: "View Details",
        chat: "Chat",
    },
    {
        "6": "Cancel Booking\n                                      \n                                      \n                                        \n                                        Edit\n                                      \n                                      \n                                        \n                                        Delete",
        courtName: "Feather Badminton",
        court: "Court 1",
        img: "assets/img/booking/booking-02.jpg",

        dateandTime: "Mon, Jul 1202:00 PM - 05:00 PM",
        payment: "$90",
        status: "Awaiting",
        details: "View Details",
        chat: "Chat",
    },
    {
        "6": "Cancel Booking\n                                      \n                                      \n                                        \n                                        Edit\n                                      \n                                      \n                                        \n                                        Delete",
        courtName: "Bwing Sports Academy",
        court: "Court 1",
        img: "assets/img/booking/booking-03.jpg",

        dateandTime: "Mon, Jul 1503:00 PM - 05:00 PM",
        payment: "$130",
        status: "Awaiting",
        details: "View Details",
        chat: "Chat",
    },
    {
        "6": "Cancel Booking\n                                      \n                                      \n                                        \n                                        Edit\n                                      \n                                      \n                                        \n                                        Delete",
        courtName: "Marsh Academy",
        court: "Court 1",
        img: "assets/img/booking/booking-04.jpg",

        dateandTime: "Mon, Jul 1605:00 PM - 07:00 PM",
        payment: "$100",
        status: "Awaiting",
        details: "View Details",
        chat: "Chat",
    },
    {
        "6": "Cancel Booking\n                                      \n                                      \n                                        \n                                        Edit\n                                      \n                                      \n                                        \n                                        Delete",
        courtName: "Wing Sports Academy",
        dateandTime: "Mon, Jul 1605:00 PM - 08:00 PM",
        img: "assets/img/booking/booking-05.jpg",
        court: "Court 1",
        payment: "$140",
        status: "Awaiting",
        details: "View Details",
        chat: "Chat",
    },
];
