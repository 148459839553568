export const usercancelleddata = [
    {
        img: "assets/img/booking/booking-01.jpg",
        courtName: "Leap Sports Academy ",
        courtNo: "Court 1",
        dateandTime: "Mon, Jul 1106:00 PM - 08:00 PM",
        payment: "$150",
        status: "Cancelled",
    },
];
